<template>
  <el-select
    v-model="id"
    placeholder="选择方式"
    style="width:268px;margin:0"
    @change="changeValue"
    filterable
    size="small"
  >
    <el-option
      style="color:#17a2b8"
      label="+新增收款方式"
      value="add"
      @click="add"
    ></el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.methodName"
      :value="item.id"
    />
  </el-select>
  <addMethod ref="addMethod" @success="updateSource" />
</template>

<script>
import { paymentMethodList } from "@/api/crm";
import addMethod from "../../components/addMethod.vue";
export default {
  name: "",
  components: {
    addMethod,
  },
  props: {
    methodId: Number,
  },

  data() {
    return {
      options: [],
    };
  },
  computed: {
    id: {
      get() {
        if (!this.methodId) {
          return "";
        }
        return this.methodId;
      },
      set(val) {
        this.$emit("update:methodId", val * 1);
        this.$emit("success", val * 1);
      },
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
        paymentMethodList({}).then((res) => {
        this.options = res.data.data.list ? res.data.data.list : [];
      });
    },
    add() {
      this.$refs.addMethod.init();
    },
    updateSource(row) {
      this.id = row.id;
      this.init();
      this.$emit("success2");
    },
  },
};
</script>

<style scoped lang="less"></style>
