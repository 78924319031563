<template>
  <el-select
    v-model="id"
    placeholder="选择来源"
    style="width:268px;margin:0"
    @change="changeValue"
    filterable
    size="small"
  >
    <el-option
      style="color:#17a2b8"
      label="+新增来源"
      value="add"
      @click="add"
    ></el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.sourceName"
      :value="item.id"
    />
  </el-select>
  <addSource ref="addSource" @success="updateSource" />
</template>

<script>
import { sourceList } from "@/api/crm";
import addSource from "../../components/addSource.vue";
export default {
  name: "",
  components: {
    addSource,
  },
  props: {
    sourceId: Number,
  },

  data() {
    return {
      options: [],
    };
  },
  computed: {
    id: {
      get() {
        if (!this.sourceId) {
          return "";
        }
        return this.sourceId;
      },
      set(val) {
        this.$emit("update:sourceId", val * 1);
        this.$emit("success", val * 1);
      },
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      sourceList({}).then((res) => {
        this.options = res.data.data.list ? res.data.data.list : [];
      });
    },
    add() {
      this.$refs.addSource.init();
    },
    updateSource(row) {
      this.id = row.id;
      this.init();
      this.$emit("success2");
    },
  },
};
</script>

<style scoped lang="less"></style>
