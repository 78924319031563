<template>
  <el-select
    size="small"
    placeholder="请选择"
    @change="changeValue"
    v-model="typeId"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.categoryName"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "selectBusiness",
  props: {
    typeId: Number,
    options: Array,
  },
  data() {
    return {};
  },
  watch: {
    options: function(newVal, oldVal) {
      //console.log(newVal);
    },
    immediate: true,
    deep: true,
  },

  methods: {
    changeValue(val) {
      this.$emit("update:typeId", this.typeId);
      this.$emit("success", this.typeId);
    },
  },
};
</script>

<style scoped lang="less"></style>
