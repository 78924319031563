<template>
  <!-- 客户 -->
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="客户"
    v-model="dialogTableVisibledaorukehu"
  >
    <div style="margin-bottom:10px;">
      <span style="color: #67c23a;margin-right:20px"
        >导入成功条数:{{ callList.success_total }}</span
      >
      <span style="color:#F15A24;margin-right:20px"
        >导入失败条数:{{ callList.fail_total }}</span
      >
      <span style="color:#F15A24" v-if="callList.fail_reason"
        >导入失败原因:{{ callList.fail_reason }}</span
      >
    </div>
    <div style="height: 450px;overflow: auto;">
      <el-table :data="callList.fail_list" size="small" border>
        <el-table-column
          align="center"
          property="customerName"
          label="导入失败公司名称"
        ></el-table-column>
        <el-table-column
          align="center"
          property="remark"
          label="原因"
        ></el-table-column>
      </el-table>
    </div>
    <div style="margin: 15px 0; padding-bottom:10px">
      <el-button
        style="float: right;margin-left:10px"
        @click="dialogTableVisibledaorukehu = false"
        size="small"
        type="primary"
        >关闭</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "daoruDialog",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisibledaorukehu: false,
      callList: [],
    };
  },
  methods: {
    init(list) {
      this.callList = list;
      this.dialogTableVisibledaorukehu = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
